/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';

/*Tipografía script*/
@import url('https://fonts.googleapis.com/css2?family=Eczar&display=swap');



//Quitando el mensaje de la licencia en cabinas de calendario
.fc-license-message {
    display: none !important;
}
.evento-google {
    border: solid 1px #0F9D58 !important;
    background-image: url(https://cdn.icon-icons.com/icons2/2631/PNG/512/google_calendar_new_logo_icon_159141.png);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: 98% 98%;
    position: relative;
    box-shadow: 0px 0px 5px #0F9D58;
    opacity: 0.5;
}
// Estilos para el botón de Google Calendar
.fc-toggleGoogleEvents-button {
    .bx-google {
      color: #4285F4;
      
      &.text-muted {
        opacity: 0.5;
      }
    }
  }


//Estilos globales

.btn-link:focus {
    outline: none;
    box-shadow: none;
}

span.rounded-circle > img,
img.noblurchrome,
a.logo img {
    image-rendering: -webkit-optimize-contrast;
}
.display-5 {
    font-size:2rem;
    line-height:1;
}
.card {
    -webkit-box-shadow: 0 0.25rem 0.8rem rgba(28, 33, 38, 0.13);
    box-shadow: 0 0.25rem 0.8rem rgba(28, 33, 38, 0.13);
    border-radius: 0rem !important;
}
.cursor-pointer {
    cursor: pointer;
}
.tarjetas_info {
    background: #0f0c29;
    background: -webkit-gradient(linear,left top,right top,from(#24243e),color-stop(#302b63),to(#0f0c29));
    background: linear-gradient(to right,#24243e,#302b63,#0f0c29);
}
  

//Quitando estilos de safari en botones
input[type=text], input[type=button], a[type=button] {
    -webkit-appearance: none;
}

//Input redes sociales en estudioo
input.redessociales::placeholder {
    text-transform:lowercase;
}

//Efecto Multiplicar imagen
.multiplicar {
    mix-blend-mode: multiply;
}

//Calendario
.modal-cambios .modal-content, .modal-calendario .modal-content {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 1em;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.modal-calendario .modal-content ul li {
    cursor: pointer;
}
.modal-calendario .form-group {
    margin-bottom: 0.5rem;
}
.modal-calendario .modal-header {
    border-bottom: 1px solid #bcbcbc;
}
.modal-calendario .form-group label.et-nombre {
    border-radius: 5px;
    padding: 1px 7px;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 1px;
}
span.cancel_cal {
    background-color: #df0a0a;
    border-radius: 5px;
    font-size: 10px;
    color: #fff;
}
span.solapada_cal {
    background-color: #1621ff;
    border-radius: 5px;
    font-size: 10px;
    color: #fff;
}
span.tipo_cal {
    background-color: #018354;
    border-radius: 5px;
    font-size: 10px;
    overflow: hidden;
    color: #fff;
}
.fc-daygrid-event-harness a.ausencia-rango {
    padding: 6px 40px 8px 7px !important;
    background-image: url('https://inkoru.com/assets/img/user-ausente.png');
    background-repeat: no-repeat;
    background-position: 95% 50%;
    border: dashed 3px #ffffff;
    font-style: italic;
}
span.whats_cal {
    border-radius: 5px;
    font-size: 10px;
}
.hide-past-events a.fc-event-past {
    display: none;
}
.btn-group-example .btn:before {
    content: "/" !important;
}
//Tooltip con la descripción
.ui-tooltip {
    background-color: #fff;
    color: #000;
    line-height: 1.3em;
    font-size: 11px;
    border: solid 1px #eee !important;
    border-radius: 10px;
    padding: 15px;
    font-family: Poppins,sans-serif;
}
/*Modo año*/
.calendario .fc-popover .fc-popover-body .fc-daygrid-event-harness .fc-event-draggable {
    background-color: #000;
}
/*Ajustes modo semana*/
.fc-timegrid-cols span.tipo_cal,
.fc-timegrid-cols br {
    display: none !important;
}
/*Tooltip con la info de la cita al hacer hover*/
.cuerpo-calendar .calendario {
    position: relative;
}
.custom-tooltip {
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    pointer-events: none;
    color: #333;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}

.tooltip-content {
    font-size: 12px;
}

.tooltip-content hr {
    margin:10px 0px;
}
a.mostrar-mas {
    background-color: rgba(0, 0, 0, 0.2);
    color: #111;
    padding: 0px 3px 0px 3px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    display: block;
    text-align: center;
}

/* Estilos para el modo pantalla completa */
.calendar-fullscreen {
    overflow: hidden;
  }
  
  .calendar-fullscreen .cuerpo-calendar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    background: #fff; /* O el color de fondo que prefieras */
  }
  
  .calendar-fullscreen .calendario {
    height: 100%;
  }
  
  .calendar-fullscreen .calendario .card-body {
    height: 100%;
    overflow: auto; /* Permitir scroll si el contenido es demasiado grande */
  }
  
  /* Opcional: animaciones para la transición */
  .cuerpo-calendar {
    transition: all 0.3s ease-in-out;
  }
  .fc-toggleFullScreen-button {
    /* Estilos base del botón */
    background: none;
    border: none;
  }
  
  .fc-toggleFullScreen-button i {
    font-size: 20px;
    line-height: 1;
  }
  
  /* Opcional: estilos al pasar el ratón */
  .fc-toggleFullScreen-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:focus {
    box-shadow: none !important;
  }
  .artist-filter-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1050; // Debe ser mayor que el z-index del calendario en pantalla completa
    max-height: 300px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  .artist-filter-menu label {
    display: block;
    margin-bottom: 5px;
  }
  .artist-filter-menu input[type="checkbox"] {
    margin-right: 5px;
  }
  .artist-color-circle {
    display: inline-block;
    width: 12px; /* Tamaño del círculo, puedes ajustarlo según tus necesidades */
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
    vertical-align: middle;
  }
  .artist-filter-menu .bx.bxs-user-circle {
    font-size: 16px;
    vertical-align: middle;
  }

  /*Filtro de ubicaciones*/
  .ubicacion-filter-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1050; // Debe ser mayor que el z-index del calendario en pantalla completa
    max-height: 300px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  .ubicacion-filter-menu label {
    display: block;
    margin-bottom: 5px;
  }
  .ubicacion-filter-menu input[type="checkbox"] {
    margin-right: 5px;
  }
  

/* Cursor para la etiqueta de la cabina cuando su columna no está expandida */
.fc-col-header-cell[data-resource-id] .fc-col-header-cell-cushion {
  cursor:zoom-in;
}
.cabina-exp[data-resource-id] .fc-col-header-cell-cushion {
  cursor:zoom-out;
}
.fc-timegrid-col.expandida {
    width: 50%;
}
th.cabina-exp {
    background-color: #e0e0e0;
}

//Destacando las citas que coinciden con la búsqueda
.desc_coincide {
    box-shadow: 0px 0px 10px #000;
    scale: 1.2;
    background-color: #fff !important;
    border: solid 1px red !important;
    transition: all 0.2s ease-out;
    margin-top: 15px !important;
}

//Estilando las horas de cierre
.fc .fc-non-business {
    background: var(--fc-non-business-color,hsla(0,100%,84.3%,.3)) !important;
}
//Que el texto de la descripción no desborde
.fc-v-event .fc-event-main-frame {
    overflow: hidden;
}
.event-descripcion {
    font-size: 10px;
    line-height: 12px !important;
    display: block;
    margin: 5px 0px;
}
.fc-daygrid-event-harness a.ausente::after {
    content: "No presentado";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
    text-transform: uppercase;
    font-weight: bold;
}
.fc-daygrid-event-harness a.cancelada::after {
    content: "Cancelada";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
    text-transform: uppercase;
    font-weight: bold;
}
.fc-daygrid-event-harness a.proyecto::after {
    content: "Proyecto";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.boceto::after {
    content: "Boceto";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.tattoo::after {
    content: "Tattoo";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.tattoosenal::after {
    content: "Tattoo ✓";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
    background-color: #34c38f;
    color: #fff;
    padding: 0px 7px;
    line-height: 12px;
    border-radius: 10px;
}
.fc-timeGridWeek-view td.fc-day a.fc-timegrid-event.tattoosenal::after {
    content: "✓";
    position: absolute;
    font-size: 10px;
    left: -18px;
    top: 0px;
    background-color: #34c38f;
    color: #fff;
    padding: 0px 5px 0px 5px;
    line-height: 12px;
    border-radius: 5px 0px 0px 5px;
    z-index: 999;
}
.fc-resourceTimeGridDay-view td.fc-day a.fc-timegrid-event.tattoosenal .fc-event-time::after {
    content: "✓";
    position: relative;
    font-size: 8px;
    left: 2px;
    top: -1px;
    background-color: #34c38f;
    color: #fff;
    padding: 0px 4px;
    line-height: 12px;
    border-radius: 3px;
}
/*Señal piercing*/
.fc-daygrid-event-harness a.piercingsenal::after {
    content: "Piercing ✓";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
    background-color: #34c38f;
    color: #fff;
    padding: 0px 7px;
    line-height: 12px;
    border-radius: 10px;
}
.fc-timeGridWeek-view td.fc-day a.fc-timegrid-event.piercingsenal::after {
    content: "✓";
    position: absolute;
    font-size: 10px;
    left: -18px;
    top: 0px;
    background-color: #34c38f;
    color: #fff;
    padding: 0px 5px 0px 5px;
    line-height: 12px;
    border-radius: 5px 0px 0px 5px;
    z-index: 999;
}
.fc-resourceTimeGridDay-view td.fc-day a.fc-timegrid-event.piercingsenal .fc-event-time::after {
    content: "✓";
    position: relative;
    font-size: 8px;
    left: 2px;
    top: -1px;
    background-color: #34c38f;
    color: #fff;
    padding: 0px 4px;
    line-height: 12px;
    border-radius: 3px;
}


.fc-daygrid-event-harness a.repaso::after {
    content: "Repaso";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.walk-in::after {
    content: "Walk-in";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.piercing::after {
    content: "Piercing";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.cambiopiercing::after {
    content: "Piercing \21BB";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.laser::after {
    content: "Láser";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.lasertatmic::after {
    content: "Láser Tattoo / Micro";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.lasereye::after {
    content: "Láser Eyeliner";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.lasercej::after {
    content: "Láser Cejas";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.laserlab::after {
    content: "Láser Labios";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.micro::after {
    content: "Micro";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.miclabios::after {
    content: "Mic.Labios";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.miccejas::after {
    content: "Mic.Cejas";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.miceye::after {
    content: "Mic.Eye";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.micmic::after {
    content: "Microblad.";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.micrep::after {
    content: "Mic.Repaso";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.miccur::after {
    content: "Curso Micro";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.micshad::after {
    content: "Shading";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.micmantcej::after {
    content: "Mant. Cejas";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.micmantojo::after {
    content: "Mant. Ojos";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.micmantlab::after {
    content: "Mant. Labios";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.manicura::after {
    content: "Manicura";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.extpest::after {
    content: "Ext. Pestañas";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.extpestret::after {
    content: "Ret. Pestañas";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.carbon::after {
    content: "Carbon Peel";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.rejuv::after {
    content: "Rej. Facial";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.depfac::after {
    content: "Depil. Facial";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.liftpest::after {
    content: "Lift. Pestañas";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.lamincejas::after {
    content: "Lamin. Cejas";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.gemas::after {
    content: "Gemas dent.";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.micotros::after {
    content: "Micro otros";
    position: absolute;
    font-size: 9px;
    right: 5px;
    top: 5px;
}
.fc-daygrid-event-harness a.whatsapp {
    background-image: url('assets/images/iconos/icono_whatsapp.png');
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 99% 95%;
}

.fc-daygrid-dot-event:hover {
    background-color: #eeeeee !important;
}

.fc .fc-daygrid-event {
    padding: 0em 0.7em 0em 0.7em !important;
}
.fc-daygrid-event {
    white-space: normal !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-right: auto;
    margin-left: auto;
}

.fc-daygrid-event-dot {
    width: 5px !important;
    box-sizing: border-box !important;
    margin: 0px !important;
}
//.fc .fc-daygrid-event-harness {
    //display:inline !important;
//}
.fc-daygrid-dot-event {
    display: block !important;
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
    white-space: normal;
    margin: 0px !important;
    font-size: 9px !important;
    line-height: 9px !important;
    margin-top: 5px !important;
    font-weight: bold !important;
    color: #474747;
    font-style: italic !important;
}
span.event-price {
    font-weight: lighter;
    background-color: white;
    color: #000;
    padding: 0px 3px;
    margin-left: 5px;
    border-radius: 20px;
}
.fc-event.festivo {
    background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgb(255, 188, 188) 0px, rgb(255, 188, 188) 8px);
}

.fc .fc-col-header-cell-cushion {
    color:#000000;
    text-transform:capitalize;
}
.fc .fc-daygrid-day-number {
    font-size: 20px;
    font-weight: bold;
    color: #222;
}
.fc .fc-toolbar-title {
    color: #000;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
}
.fc-daygrid-event-dot {
    border-radius: 20px !important;
    height: 5px !important;
}
.fc .fc-daygrid-event {
    color: #444444;
    border: solid 1px #ffffff;
    margin-bottom: 0px;
    padding-top: 0px !important;
}
.fc-timegrid-event .fc-event-main {
    overflow: hidden;
}
.fc .fc-daygrid-event-harness {
    /*height:20px;*/
    cursor:pointer !important;
}
span.trabajodescripcion {
    font-size: 11px;
    display: block;
    font-weight: normal;
    margin-top: 5px;
    line-height: 1em;
    margin-bottom: 5px;
}
.aclaracion_artistas {
    display:inline-block;
}
.color_corresponde {
    height: 15px;
    width: 15px;
    border-radius: 15px;
    display: inline-block;
    background-color:#ffffff;
}
.filtros-calendario {
    background-color: #111111 !important;
    border-top-left-radius: 1rem!important;
    border-top-right-radius: 1rem!important;
    border-bottom-left-radius: 0rem!important;
    border-bottom-right-radius: 0rem!important;
    border-bottom: solid 1px #333;
}
.calendario {
    border-top-left-radius: 0rem!important;
    border-top-right-radius: 0rem!important;
    border-bottom-left-radius: 1rem!important;
    border-bottom-right-radius: 1rem!important;
}
/*Estilando los eventos de ausencias*/
.fc-daygrid-event-harness > a.ausencia {
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: bold;
    padding: 0px 0px 0px 5px !important;
}

.fc-daygrid-event-harness > a.ausencia > .fc-event-main > .fc-event-main-frame > .fc-event-title-container > span.event-descripcion {
    display: none;
}

/*Estilando el modulo de artistas ausentes*/
.mod-artistas-ausentes {
    background-color: #efefef !important;
}
/*Estilando los eventos cancelados del calendario*/
.calendario .fc-daygrid-day-events a.cancelada {
    text-decoration: line-through !important;
    color: red !important;
    opacity: 0.4;
}
.calendario .fc-timegrid-event-harness a.cancelada {
    text-decoration: line-through !important;
    background-color: red !important;
    border-color: red !important;
    opacity: 0.3;
}
.calendario table.fc-list-table tr.cancelada {
    background-color: #ffeaea;
}
.calendario table.fc-list-table tr.cancelada td.fc-list-event-graphic span {
    border-color: red !important;
}
.calendario table.fc-list-table tr.cancelada td.fc-list-event-time, 
.calendario table.fc-list-table tr.cancelada td.fc-list-event-title {
    text-decoration: line-through !important;
}


.calendario .fc-daygrid-day-events a.ausente {
    text-decoration: line-through !important;
    color: red !important;
    opacity: 1;
}
.calendario .fc-timegrid-event-harness a.ausente {
    text-decoration: line-through !important;
    background-color: red !important;
    border-color: red !important;
    opacity: 1;
}
.calendario table.fc-list-table tr.ausente {
    background-color: #ffeaea;
}
.calendario table.fc-list-table tr.ausente td.fc-list-event-graphic span {
    border-color: red !important;
}
.calendario table.fc-list-table tr.ausente td.fc-list-event-time, 
.calendario table.fc-list-table tr.ausente td.fc-list-event-title {
    text-decoration: line-through !important;
}
//Página de gastos
.bg_rayado {
    background-image: linear-gradient(45deg, #ffffff 41.67%, #d2d6fa 41.67%, #d2d6fa 50%, #ffffff 50%, #ffffff 91.67%, #d2d6fa 91.67%, #d2d6fa 100%);
    background-size: 10.94px 10.94px;
}
//Vista de trabajo
//Galería de fotos
.galeria_fotos_trabajo {
    border-radius: 20px 20px 0px 0px !important;
}
.opciones_fotos_tattoo {
    background-color: #ffffff;
}

//Listado de trabajos
.filtro-listado-trabajos {
    background-color: #efefef !important;
    border-bottom-left-radius: 0rem!important;
    border-bottom-right-radius: 0rem!important;
}
.listado-trabajos {
    border-top-left-radius: 0rem!important;
    border-top-right-radius: 0rem!important;
    border-bottom-left-radius: 1rem!important;
    border-bottom-right-radius: 1rem!important;
}
.check-crearcitas {
    border-top-left-radius: 0.25rem!important;
    border-top-right-radius: 0.25rem!important;
    border-bottom-left-radius: 0rem!important;
    border-bottom-right-radius: 0rem!important;
}
.select-suavizado {
    background-color: #fbfbfb;
    border-radius: 50px;
    border: solid 1px #e7e7e7;
    font-size: 12px;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(assets/images/flecha-abajo.png);
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    padding-right: 30px;
}
.inputfecha-suavizado {
    background-color: #fbfbfb;
    border-radius: 50px;
    border: solid 1px #e7e7e7;
    font-size: 12px;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(assets/images/flecha-abajo.png);
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: 95% 50%;
}
a.btn-filtrartrabajos,
a.btn-borrarfiltrostrabajos {
    border-radius:50px !important;
}

//Nuevo trabajo
.zonas_create_work span.selected-item span,
.zona-trabajo span.selected-item span {
    white-space: nowrap !important;
}

//Navegación
.menu-usuario a {
    cursor:pointer;
}
a.mi-perfil-mng {
    cursor:pointer;
}

//Modales de info
button.btn_cerrar {
    opacity: 1;
}
button.btn_cerrar i {
    text-shadow:0 0 0;
}

//Página de pagos
#listado_tarifas .whatsapp {
    background-image: url('/assets/images/iconos/icono_whatsapp.png');
    background-repeat: no-repeat;
    background-position: 50% 6px;
    background-size: 85px;
    background-color: #defde0;
}

.modulo_whats {
    background-color: #ffffff;
    border-radius: 10px !important;
    border: solid 1px #00935d;
}

//Tipografía manual
.comentario-cita {
    font-family: 'Eczar', serif;
    line-height:1em;
    word-break: normal;
}

//Paginación
.page-item.active .page-link {
    background-color: #000;
    border-color: #111;
}

//Clase requerido en formularios
label.requerido::after {
    content: "*";
    color: red;
    font-size: 10px;
    position: absolute;
}

/*Vista de productos*/
.tags-container {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
}

.tags-container::-webkit-scrollbar {
    width: 6px;
}

.tags-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.tags-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.tags-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.gap-2 {
    gap: 0.5rem;
}

.tags-container {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    background: #fff;
}

.button-items .btn {
    margin-right: 0.5rem;
}

/* Ajuste responsive para los botones */
@media (max-width: 991.98px) {
    .d-flex.justify-content-lg-end {
        justify-content: flex-start !important;
    }
}
.separator {
    margin: 1rem 0;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
    opacity: 0.5;
}










//Authentication
.auth-full-bg{
    .bg-overlay{
        background-image: url(assets/images/bg-autenticacion.jpg);
        background-position: center;
        opacity: 1;
    }
}
.psw-olvidado {
    cursor: pointer;
}
img.logo-studios-login {
    max-width: 60%;
}

//Dashboard - Importe total
.eye-icon {
    cursor: pointer;
    display: inline-block;
}

//Dashboard - Cliente request
.cliente-request {
    border-bottom: solid 1px #75798d;
}
.mod_solicitud_cliente {
    background-image: url(assets/images/cliente_solicitud_bg.png);
    background-repeat: no-repeat;
    background-position: 120% 120%;
}

.eye-icon i {
    transition: transform 0.3s ease-in-out;
}

.eye-icon.rotate i {
    transform: rotate(180deg);
}  
.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}
  
.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

@keyframes fadeIn {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}

@keyframes fadeOut {
from {
    opacity: 1;
}
to {
    opacity: 0;
}
}

//Inventario
.radius5 {
    border-radius: 5px !important;
}
//Liquidación de estudio
td.cancelada, td.cancelada a {
    text-decoration: line-through;
    color: #ff000078 !important;
    font-style: italic;
}
//Dashboard - Módulo del usuario
.modulo-usuario > .cabecera {
    background-image: url('assets/images/bg-user-card.jpg');
    background-position: center;
    background-size: cover;
}
.modulo-usuario > .cabecera h4.saludo {
    text-shadow: 2px 2px 0px #333;
}

//Dashboard - Módulo del artista
.modulo-artista > .cabecera {
    background-image: url('assets/images/bg-dashboard-artista.jpg');
    background-position: center;
    background-size: cover;
}
.modulo-artista > .habbility {
    background-image: url('assets/images/bg-habbility.jpg');
    background-position: center;
    background-size: cover;
}
.modulo-artista > .distribuidores {
    background-image: url('https://inkoru.com/material/bg-supplies-b.jpg');
    background-position: center;
    background-size: cover;
}
//Dashboard - Módulo del panel nuevo artistas
.modulo-webappartista > .cabecera {
    background-image: url('assets/images/bg-dashboard-artista.jpg');
    background-position: center;
    background-size: cover;
}
.modulo-webappartista > .habbility {
    background-image: url('assets/images/bg-panel-artistas.jpg');
    background-position: center;
    background-size: cover;
}
.modulo-webappartista > .distribuidores {
    background-image: url('https://inkoru.com/material/bg-supplies-b.jpg');
    background-position: center;
    background-size: cover;
}
//Dashboard - Módulo del manager
.modulo-manager > .cabecera {
    background-image: url('assets/images/bg-dashboard-manager.jpg');
    background-position: bottom;
    background-size: cover;
}

//Dashboard - Módulo del cliente
.modulo-cliente > .cabecera {
    background-image: url('assets/images/bg-dashboard-clientes.jpg');
    background-position: center;
    background-size: cover;
    border-radius: 5px 5px 0px 0px !important;
}

// ESTILANDO LA CONFIGURACIÓN INICIAL
.formulario-conf-inicial {
    background: #141E30;
    background: -webkit-linear-gradient(to right, #243B55, #141E30);
    background: linear-gradient(to right, #243B55, #141E30);
    border: solid 2px #141e30;
}
.twitter-bs-wizard .twitter-bs-wizard-nav {
    background-color:transparent !important;
}
.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active .step-number {
    background-color: #101010 !important;
    font-weight: bold;
}
.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active {
    color: #ffffff;
}

.disabled_hours{
    opacity: 0.3;
    pointer-events: none;
}
.logo-img-fluid{
    width:100px;
}
.selector_wizard, .wizard_nav {
    display: flex;
    list-style: none;
}
.modalFormulario{
    .modal-dialog{
        width: 100%;
        max-width: 1300px;
    }
    .modal-content{
        padding:20px;
    }
}
.nuevo_cliente{
    display:flex;
    align-items: center;
}
.auth-body-bg{
    .container-fluid{
        max-width: 100% !important;
    }
}
.wizard_nav{
    justify-content: space-between;
    align-items: center;
    li{
        cursor: pointer;
        a.disabled{
            opacity: 0.2;
        }
    }
}
.formfile{
    padding: 20px;
    border: 1px solid;
    border: 2px dashed #ced4da;
    background: #fff;
}

.bloque_firma{
    canvas{
        border: 2px dashed #ced4da;
        margin: 20px 0px 0px;
    }
}
.firma-optimizada .bloque_firma canvas{
    background-color: #ffffff;
    border: 2px dashed #ced4da;
    margin: 20px 0px 0px;
}
tr.dia_oculto{
    display:none;
}
// Menú principal de navegación
.topnav {
    background: #101010;
    top: 0px !important;
}
.topnav .navbar-nav .nav-link {
    padding: .5rem 1.3rem;
    color:#fbfbfb;
}
body[data-topbar="dark"] .navbar-brand-box {
    background: transparent;
}
body[data-topbar="dark"] #page-topbar {
    background-color: #202637 !important;
    background-image: url(assets/images/loop_bg.jpg);
    background-repeat: repeat;
    background-size: 250px;
    background-blend-mode: overlay;
}

//Botón de Acceder con Google
.btn-google {
    background-color: #4285F4;
    color:#ffffff;
    font-family: 'Roboto', sans-serif;
}
.btn-google:hover {
    background-color: #3367d6;
    color:#ffffff;
}
.btn-google .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255,255,255,1);
    border-right: 1px solid rgba(255,255,255,.1);
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

//Listado de artistas
.ico_conexion_calendar {
    position: absolute;
    top: 5px;
    left: 5px;
}
.ico_conexion_calendar .no_conectado img {
    opacity: 0.5;
}
.info_artistas_archive {
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
    top: 95px;
    width: 100%;
}
.contenedor_estilos {
    max-height: 20px;
    overflow: hidden;
}
a.nombre_persona {
    text-transform: capitalize;
}



//Estilo de días festivos
.dia-festivo {
    display: inline-block;
    border: solid 1px red;
    padding: 5px 5px 0px 5px;
    border-radius: 5px;
    position: relative;
}
.diafestivo {
    background-color: red !important;
    border: dashed #900404 1px !important;
    border-radius: 5px !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 9px !important;
    letter-spacing: 1px;
    font-style: italic;
    box-shadow: 1px 1px 2px red;
    line-height: 1em;
}
span.diaf {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    display: block;
    background-color: red;
    color: #fff;
    border-radius: 3px;
}
span.mesf {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}
.eliminar-festivo {
    position: absolute;
    top: -11px;
    right: -10px;
    background-color: #000;
    color: #fff;
    padding: 3px;
    border-radius: 10px;
}
//Calendario estilo día festivo
td.festivo {
    background-size: auto auto;
    background-color: rgba(255, 255, 255, 1);
    background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 188, 188, 1) 0px, rgba(255, 188, 188, 1) 8px );
}
//Calendario forzando el color blanco
a.txtblanco,
a.txtblanco > div.fc-event-time {
    color:#ffffff !important;
}
a.fc-daygrid-dot-event.txtblanco:hover {
    background-color: #000000!important;
}

//Fondos de las tarjetas
.tarjeta_azul_osc {
    background: #0f0c29;
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
}
.tarjeta_brillo {
    background-image: radial-gradient( circle 827px at 47.3% 48%,  rgba(255,255,255,1) 0%, rgba(227,227,227,1) 30% );
}
.tarjeta_dark {background-image: linear-gradient(to right, #434343 0%, black 100%);}

//Módulo "Citas para hoy"
.citas_para_hoy .list-group-item {
    background-color: transparent;
    border: none;
}
//Módulo "Citas de la semana"
table.citas_semana tr td {
    border-top:none !important;
}

/* Subiendo el z-index del selector de horas/minutos, para que quede por encima del modal de "modificar cita" */
.polyfill-right-aligned {
    z-index:1051 !important;
}
/* Estilando el datepicker */
#ui-datepicker-div {
    border: none;
    box-shadow: 0px 0px 15px 2px #ccc;
    padding: 15px;
    border-radius: 10px;
}
.ui-datepicker-header {
    background-color: transparent;
    border: none;
}
a.ui-state-default {
    border: 1px solid #fff !important;
    background: #fff !important;
}
.ui-datepicker td a {
    text-align: center !important;
}
a.ui-state-active {
    color: #fff !important;
    font-weight: bolder !important;
    background-color: #556ee6 !important;
    border-radius: 50px;
    border: none !important;
    width: 25px;
}
td.ui-datepicker-today a {
    color: #000;
    font-weight: bold;
    background-color: #ededed !important;
}

//Sección comparativa aristas
.filtro-comparativa-artistas {
    margin-left: -20px !important;
    margin-right: -20px !important;
}
p.text-no-info {
    position: absolute !important;
    top: 50%;
}
//Modal Seleccionar cliente
.modal-personalizado {
    width: 50% !important; 
}
a.nombrecliente {
    cursor: pointer;
}
//Visor de video para capturar fotos del dni
.video-container {
    width: 100%;
    padding-top: 75%; /* 4:3 aspect ratio */
    position: relative;
    overflow: hidden;
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

//Tabla de consentimiento andaluz
h5.titulo_cons_and {
    color:#487648;
}
table.tabla_ci_andalucia {
    border: solid 2px #487648;
}
table.table-sm.tabla_ci_andalucia td {
    padding: 0.2rem !important;
}
table.tabla_ci_andalucia tr {
    border-bottom: solid 2px #487648;
}
table.tabla_ci_andalucia td {
    border-right: solid 2px #487648;
    color:#487648;
    font-size:12px;
}

// RSPNSV
@media only screen and (min-width: 992px) {
    .collapse.colapsa-en-md {
        display: block;
        height: auto !important;
        visibility: visible;
      }
}
@media only screen and (max-width: 575px) {
    .filtro_clientes,
    .buscador_de_clientes,
    .filtro_proyectos,
    .buscador_de_proyectos {
        width: 100%;
    }
    .filtro_clientes a,
    .filtro_proyectos a {
        width: 100%;
    }
    .bloque_firma canvas {
        width:100%;
    }
    span.nombre_paso {
        display:none;
    }
    a.btn-next-citas, button.btn-save-citas {
        display:block;
        width:100%;
    }
    .mvl-100 {
        width:100%;
    }
    .inkoru-faqs .nav-pills a img.avatar-sm {
        height: 2rem;
        width: 2rem;
    }
    /*Calendario móvil*/
    //.cuerpo-calendar {
        //margin-left: -35px !important;
        //margin-right: -37px !important;
    //}
    .fc-daygrid-event-harness a.ausencia-rango {
        background-image:none !important;
    }
    //.cuerpo-calendar .calendario .fc-view-harness {
        //height: 750px !important;
    //}
    .calendario .card-body {
        padding: 0px !important;
    }
    .calendario .fc-toolbar-title {
        font-size: 1.15em !important;
        background-color: #2c3e50 !important;
        color: #fff !important;
        padding: 15px 0px !important;
        border-top: solid 1px #1a252f !important;
        border-bottom: solid 1px #1a252f !important;
    }
    .calendario .fc .fc-toolbar {
        display: inline-block !important;
    }
    .calendario .fc .fc-daygrid-day-number {
        font-size: 12px !important;
    }
    .calendario .fc .fc-daygrid-day-frame .fc-daygrid-event-harness {
        //height: 30px !important;
        cursor: pointer !important;
        border-bottom: none !important;
        padding-top: 0px !important;
    }
    .calendario .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0em !important;
    }
    .calendario .fc .fc-daygrid-day-frame .fc-daygrid-event-dot {
        width: 0px !important;
    }
    .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
        border-bottom-right-radius: 0 !important;
    }
    .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {
        border-bottom-left-radius: 0 !important;
    }
    .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
        border-top-right-radius: 0 !important;
    }
    .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {
        border-top-left-radius: 0 !important;
    }
    .calendario .fc-header-toolbar > .fc-toolbar-chunk {
        margin-bottom: 0rem !important;
    }
    .calendario .fc .fc-toolbar.fc-header-toolbar {
        text-align: center !important;
    }
    .calendario .fc-toolbar-chunk .fc-button-group {
        width: 100% !important;
    }
    .calendario .fc-toolbar-chunk button.fc-today-button {
        display: none !important;
    }
    .calendario .fc-daygrid-event-dot {
        height: auto !important;
    }
    div.fc-daygrid-day-events > div.fc-daygrid-event-harness > a {
        padding: 1px !important;
    }
    .fc-daygrid-dot-event .fc-event-title {
        font-weight: normal !important;
        font-size: 10px !important;
        line-height: 1em;
        margin-top: 5px;
    }
    .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
        margin-right:0px !important;
    }
    .fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
        margin-left: 0px !important;
    }
    .fc .fc-daygrid-event {
        margin-top: 0px !important;
    }
    .fc .fc-daygrid-event {
        margin-bottom: 1px !important;
    }
    .fc-daygrid-event {
        border-radius: 0px !important;
    }
    a.ausencia .fc-event-title {
        line-height: 1em;
    }
    .fc-daygrid-event-harness a.boceto::after,
    .fc-daygrid-event-harness a.proyecto::after,
    .fc-daygrid-event-harness a.tattoo::after,
    .fc-daygrid-event-harness a.walk-in::after,
    .fc-daygrid-event-harness a.repaso::after,
    .fc-daygrid-event-harness a.piercing::after,
    .fc-daygrid-event-harness a.cambiopiercing::after,
    .fc-daygrid-event-harness a.laser::after,
    .fc-daygrid-event-harness a.micro::after,
    .fc-daygrid-event-harness a.manicura::after,
    .fc-daygrid-event-harness a.tattoosenal::after
    {
        position:initial;
    }
    .fc-daygrid-event-harness a.lasertatmic::after,
    .fc-daygrid-event-harness a.lasereye::after,
    .fc-daygrid-event-harness a.lasercej::after,
    .fc-daygrid-event-harness a.laserlab::after,
    .fc-daygrid-event-harness a.micshad::after, 
    .fc-daygrid-event-harness a.micmantcej::after,
    .fc-daygrid-event-harness a.micmantojo::after,
    .fc-daygrid-event-harness a.micmantlab::after,
    .fc-daygrid-event-harness a.extpest::after,
    .fc-daygrid-event-harness a.extpestret::after,
    .fc-daygrid-event-harness a.carbon::after,
    .fc-daygrid-event-harness a.rejuv::after,
    .fc-daygrid-event-harness a.depfac::after,
    .fc-daygrid-event-harness a.liftpest::after,
    .fc-daygrid-event-harness a.lamincejas::after,
    .fc-daygrid-event-harness a.gemas::after,
    .fc-daygrid-event-harness a.micotros::after
    {
        position: relative;
        left: 0px;
        line-height: 10px !important;
        display: inherit;
        margin-bottom: 7px !important;
    }

    .fc-daygrid-event-harness a.cancelada::after {
        content: "Cancel" !important;
        position: initial !important;
    }
    .fc-daygrid-event-harness a.tattoosenal::after {
        position: initial;
        font-size: 7px;
    }
    div.fc-daygrid-day-events > div > a > div.fc-daygrid-event-dot,
    a.diafestivo .event-descripcion {
        display: none;
    }
    .event-descripcion {
        font-size: 9px !important;
        line-height: 1em !important;
    }
    .bloque_firmado img {
        max-width: 250px;
    }
    /*Botones custom del calendario*/
    button.fc-artistFilter-button,
    button.fc-toggleFullScreen-button,
    button.fc-toggleGoogleEvents-button {
        display: block !important;
        width: 100%;
        margin-left: 0px !important;
        border-radius: 0px !important;
        border-top: solid 1px #000000 !important;
    }
    span.tipo_cal {
        font-size: 7px;
        font-weight: lighter;
    }
    .fc-event span.font-size-10 {
        font-size: 8px !important;
    }
    a.mostrar-mas {
        padding: 4px 3px 2px 3px !important;
        font-size: 8px !important;
        letter-spacing: 0px !important;
    }
    span.trabajodescripcion {
        font-size: 9px !important;
        line-height: 11px;
        color: #1c1c1c;
        font-family: helvetica, sans-serif;
        text-transform: uppercase;
    }
    .fc-event .p-1 {
        padding: 0rem !important;
    }
    .fc-scrollgrid-sync-inner span.fc-col-header-cell-cushion {
        font-size: 7px;
        font-weight: bolder;
        text-transform: uppercase;
        line-height: 8px;
        text-align: left;
    }
    a > div > div > span.text-primary.font-weight-bold.badge.badge-light {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    
    

}

.member_disabled{
    opacity: 0.3;
    pointer-events: none;
}
.member_failed{
    opacity: 0.3;
}
.element_recuento_artista{
    display: block;
    border-bottom: 1px solid #0a0a0e;
    margin-bottom: 15px;
    padding-bottom: 15px;
    &:last-child{
        border-bottom: none;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }
}

.hidden{
    display:none;
}
/* Página de precios - pago */
.plan-box.tarjeta_dark .media .media-body h5,
.plan-box.tarjeta_dark .media .media-body p,
.plan-box.tarjeta_dark h2 {
    color:#ffffff !important;
}
/* Modales relacionados con las suscripciones */
#modal_pago .modal-header,
#modal_cancelar_plan .modal-header {
    background-color: #f5f6fa;
}
#modal_pago .modal-body,
#modal_pago .modal-footer,
#modal_cancelar_plan .modal-body,
#modal_cancelar_plan .modal-footer {
    background-color: #edeef3;
}
#modal_pago button.close,
#modal_cancelar_plan button.close {
    opacity: 1;
}
#modal_pago button.close i,
#modal_cancelar_plan button.close i {
    background-color: #e3e4e8;
    border-radius: 50px;
    padding: 5px;
    color: #7b7f88;
    text-shadow: none;
}

/** Validacion de campos de formulario*/
input.ng-touched.ng-invalid,
select.ng-touched.ng-invalid {
    border:solid 2px #f46a6a;
}
::-webkit-input-placeholder { /* Edge */
    color:#eeeeee !important;
    font-style: italic !important;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
color:#eeeeee !important;
font-style: italic !important;
}
::placeholder {
color:#eeeeee !important;
font-style: italic !important;
}

.zonas_create_work .multiselect-dropdown, .select_client_work .multiselect-dropdown {
    background-color: #fff;
    border-radius: 5px;
}
.select_client_work .multiselect-dropdown > div > span,
.zonas_create_work .multiselect-dropdown > div > span {
    border:none !important;
}
.select_client_work .multiselect-dropdown{
    .dropdown-btn{
        .selected-item{
            max-width: inherit !important;
        }
    }
}
#ui-datepicker-div{
    position: absolute !important;
}
.tintas_bloque{
    margin: 20px 0px;
    .delete_line{
        background: #f36a69;
        border-radius: 100%;
        color: #fff;
        font-size: 17px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    table{
        thead{
            background: #000;
            border: 2px solid #fff;
            th{
                padding:10px;
                color:#fff;
                text-align: center;
            }
        }
        tr{
            border: 2px solid #fff;
            background:#ddd;
            td{
                padding:10px;
            }
        }
    }
}
.checkbox_aproximado{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    .form-group, .custom-control {
        margin: 0 !important;
    }
}
.checkbox_aproximado_edit{
    color:#495057;
}
.text_aprox_modal{
    font-size: 11px;
    margin-top: 6px;
    margin-bottom: 0;
}
.future_events .fc-day-past{
    opacity: 0.3;
}
.disabled_tinta{
    opacity: 0.8;
    pointer-events: none;
}
.disabled_aguja{
    opacity: 0.8;
    pointer-events: none;
}
.disabled_comision{
    pointer-events: none;
}

/* Estilos dark nuevos*/
.page-content {
    background:linear-gradient(135deg, #e8e8e8 0%, #ffffff 50%, #FFFFFF 100%);
}
body.dark-mode .page-content {
    background:linear-gradient(135deg, rgba(20,76,89,1) 0%, rgba(21,19,24,1) 50%, rgba(76,54,51,1) 100%);
}
.shadow-sm {
    box-shadow: 0.1rem 0.1rem 1rem rgba(255,255,255,0.1)!important;
}
.borde-oscuro {
    border-color:#282829 !important;
}
.footer {
    color: #ffffff;
    background-color: #111111;
}
.breadcrumb-item>a, .breadcrumb-item {
    color: #949494;
}
.listado-clientes
 {
    border-radius: 1rem 1rem 0rem 0rem !important;
}
.listado-trabajos {
    border-radius: 0rem !important;
}
.listado-emails {
    border-radius: 1rem 1rem 0rem 0rem !important;
}
.lista_clientes_email .selected-item {
    max-width: 100% !important;
}
.paginacion-clientes, .paginacion-emails {
    border-radius: 0rem 0rem 1rem 1rem !important;
}
.page-item.active .page-link {
    box-shadow: 0px 0px 6px 0px #ffffff;
}
.fondo_firma_cliente {
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: right top;
}
a.pointer {
    cursor: pointer !important;
}
/*Tickets*/
.separador-ticket {
    overflow: hidden;
    height: 22px;
    width: 80%;
}
.tabla-ticket {
    width: 80%;
}
.tabla-categorias tr:last-child {
    border-bottom: none !important;
}
.seleccion_cliente .multiselect-dropdown,
.seleccion_multiple .multiselect-dropdown {
    .dropdown-btn{
        .selected-item{
            max-width: inherit !important;
        }
    }
}
.top-listado {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.listado {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #fff !important;
}

/*Aviso inspecciones*/
.aviso-inspeccion {
    z-index: 99999;
    width: 99%;
    margin: 0 auto;
    top: 5px;
    opacity: 0.9;
    text-align: center;
}
/*En el modal de comunicaciones, al presionar sobre el botón de añadir link, el popup queda por debajo*/
.modal-tatuaje-cicatrizado .NgxEditor__Wrapper .NgxEditor__MenuBar,
.modal-interes-curacion .NgxEditor__Wrapper .NgxEditor__MenuBar {
    overflow: inherit;
}

.ui-datepicker .specialDate a {
    background: #F5293B !important;
    color: #fff !important;
    font-weight: bolder !important;
    border-radius: 50px;
    border: none !important;
    width: 25px;
    &.ui-state-active {
        background: #86293B !important;
    }
}

/*Clientes duplicados*/
tr.nombre-duplicado td:nth-child(1),
tr.nombre-duplicado td:nth-child(3),
tr.nombre-duplicado td:nth-child(4),
tr.nombre-duplicado td:nth-child(5) {
    display: none;
}

/*Tabla buscador de trabajos*/
.fila-par, .descripcion-par {
    background-color: #d8d7d7;
  }
  
.fila-impar, .descripcion-impar {
    background-color: #ffffff;
}
.descripcion-par, .descripcion-impar {
    border-bottom: solid 10px #fff;
}
.highlighted-text {
    background-color: yellow;
    color: red;
  }
.form-control:disabled, .form-control[readonly] {
    background-color: #ebebeb;
    opacity: 1;
}
.modal-backdrop{
    z-index: 1040 !important;
}

/*Carrusel info cita multiple*/
#carruselinfocitamultple .carousel-control-next-icon,
#carruselinfocitamultple_modal .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

#carruselinfocitamultple .carousel-control-prev-icon,
#carruselinfocitamultple_modal .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l1.5 1.5-2.5 2.5 2.5 2.5-1.5 1.5-4-4 4-4z'/%3e%3c/svg%3e");
}

/* Placeholder */
.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 0.5;
  }
  .placeholder.btn::before {
    display: inline-block;
    content: "";
  }
  
  .placeholder-xs {
    min-height: 0.6em;
  }
  
  .placeholder-sm {
    min-height: 0.8em;
  }
  
  .placeholder-lg {
    min-height: 1.2em;
  }
  
  .placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
            animation: placeholder-glow 2s ease-in-out infinite;
  }
  
  @-webkit-keyframes placeholder-glow {
    50% {
      opacity: 0.2;
    }
  }
  
  @keyframes placeholder-glow {
    50% {
      opacity: 0.2;
    }
  }
  .placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
            mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
            mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
            animation: placeholder-wave 2s linear infinite;
  }
  
  @-webkit-keyframes placeholder-wave {
    100% {
      -webkit-mask-position: -200% 0%;
              mask-position: -200% 0%;
    }
  }
  
  @keyframes placeholder-wave {
    100% {
      -webkit-mask-position: -200% 0%;
              mask-position: -200% 0%;
    }
  }

  //Configuración del calendario
  .calendar-config-modal .modal-content {
    background-color: #1a1a1a !important;
    color: #ffffff;
    border-radius: 12px;
    border: none;
  }